<template>
    <li class="nav-item dropdown user user-menu">
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
            <img src="https://cdn.icon-icons.com/icons2/2483/PNG/512/user_icon_149851.png"
                class="user-image img-circle elevation-2" alt="User Image">
            <span class="hidden-xs">{{usuarionombre}}</span><br>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <div class="card card-widget widget-user-2">

                <div class="widget-user-header bg-warning">
                    <div class="widget-user-image">
                        <img class="img-circle elevation-2"
                            src="https://cdn.icon-icons.com/icons2/2483/PNG/512/user_icon_149851.png" alt="User Avatar">
                    </div>
                    <h3 class="widget-user-username">{{ usuarionombre }}</h3>
                    <h3 class="widget-user-username">@{{ dominiousuario }}</h3>
                </div>

            </div>
            <a href="#" class="dropdown-item" @click="guardarpestanyas();cerrarSesion()">
                <i class="fas fa-sign-out-alt mr-2"></i> Cerrar sesión
            </a>

        </div>
    </li>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import { messaging, deleteToken } from '@/firebase';

export default({
    data() {
       
        return {
            usuarionombre: '',
            idusuario:'',
        };
    },
    methods: {
        cargardatos() {
            this.usuarionombre = localStorage.getItem('tramitador');
            this.idusuario = localStorage.getItem('usuario');
            this.dominiousuario = localStorage.getItem('subdomain');


        },
        async cerrarSesion() {
            //put https://api.pwgs.apuyen/modulos/pwgsapi/index.php/usuarios/:id/cerrar-sesion
            const api = new PwgsApi();
            
            try {
                api.get('logout');
                localStorage.removeItem('token');
                this.$router.push('/login');
                deleteToken(messaging)
                    .then(() => {
                        console.log('Token de FCM eliminado correctamente.');
                        
                        // Anular registro del Service Worker
                        navigator.serviceWorker.getRegistrations().then((registrations) => {
                            for (let registration of registrations) {
                                registration.unregister().then((unregistered) => {
                                    if (unregistered) {
                                        console.log('Service Worker desregistrado correctamente.');
                                    } else {
                                        console.log('No se pudo desregistrar el Service Worker.');
                                    }
                                }).catch((err) => {
                                    console.log('Error al desregistrar el Service Worker:', err);
                                });
                            }
                        });

                    })
                    .catch((err) => {
                        console.log('Error al eliminar el token de FCM:',err);
                    });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

            }
    },
         async guardarpestanyas() {
            const api = new PwgsApi();          
            let aux = this.$store.getters.ventanas;
            let subidadatos = { pestanas: aux };
            
            try {
                await api.put('usuarios/' + this.idusuario + '/pestanas', subidadatos);
                this.$store.dispatch('cerrarTodoDelTodo');

            }
            catch (error) {
                console.log("error", error);
            }

        },


    },
  mounted() {
      this.cargardatos();
  }
})
</script>
